
@font-face {
    font-family: 'Gilroy';
    font-weight: 400;
    font-style: normal;
    src:
            url('./fonts/Gilroy-Regular.woff2') format('woff2'),
            url('./fonts/Gilroy-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: 500;
    font-style: normal;
    src:
            url('./fonts/Gilroy-Medium.woff2') format('woff2'),
            url('./fonts/Gilroy-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Gilroy';
    font-weight: 700;
    font-style: normal;
    src:
    url('./fonts/Gilroy-Medium.woff2') format('woff2'),
    url('./fonts/Gilroy-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Times';
    font-weight: 400;
    font-style: normal;
    src:
            url('./fonts/TimesNewRomanPSMT.woff2') format('woff2'),
            url('./fonts/TimesNewRomanPSMT.woff') format('woff');
}

@font-face {
    font-family: 'Times';
    font-weight: 400;
    font-style: italic;
    src:
            url('./fonts/TimesNewRomanPS-ItalicMT.woff2') format('woff2'),
            url('./fonts/TimesNewRomanPS-ItalicMT.woff') format('woff');
}

@font-face {
    font-family: 'Times';
    font-weight: 700;
    font-style: italic;
    src:
            url('./fonts/TimesNewRomanPS-BoldItalicMT.woff2') format('woff2'),
            url('./fonts/TimesNewRomanPS-BoldItalicMT.woff') format('woff');
}

* {
    box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Gilroy, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    line-height: 1.5;
    color: #525252;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPaper-root {
    transition: none !important;
    border: 1px solid #525252;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.MuiList-root {
    padding-top: 0!important;
    padding-bottom: 0!important;
}


.MuiFormControl-root, .MuiInput-root {
     width: 230px;
 }

.MuiFormControl-fullWidth {
     width: 100%;
 }

.MuiIconButton-root:hover {background: none!important }

.MuiPopover-paper{
    font-family: Gilroy, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #525252;
}
.MuiPopover-paper .MuiPickersDay-daySelected {
    background-color: rgba(188, 149, 126, 0.5);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.MuiFormHelperText-root.Mui-error {
    font-family: Gilroy;
    font-size: 10px;
    color: #DB533D!important;
}

.MuiPickersClock-pin, .MuiPickersClockPointer-pointer, .MuiPickersClockPointer-noPoint, .MuiPickersToolbar-toolbar {
    background-color: #BC957E!important;
}
.MuiPickersClockPointer-thumb{
    border: 14px solid #BC957E!important;
}



.MuiRadio-root {
    color: #A8A8A8!important;
}

.Mui-checked .MuiSvgIcon-root:last-child path {
    fill: #BC957E!important;
}

.MuiPickersToolbar-toolbar {
    height: 80px!important;
}
